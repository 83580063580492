import React from "react"
import { Link } from 'gatsby'
import resolveLink from 'src/utils/resolveLink'
import FluidImage from 'src/utils/FluidImage'
import PadlockIcon from "src/images/padlock.svg"
import { useIdentityContext } from 'react-netlify-identity-gotrue'

const ArchiveItem = (props) => {
    // Since we're using this for blocks and archive we have to check if the content is the proper format
    // Blocks that work with GetX.js will return an object, archives will return a string
    const data = props.content.constructor.name === 'Object' ? props.content : JSON.parse(props.content)
    const tags = props.tag_list.join(', ')
    const date = new Date(props.first_published_at).toLocaleDateString("en-GB", { day: 'numeric', month: 'long', year: 'numeric', })

    // Get user role
    const identity = useIdentityContext()
    const roles = identity?.user?.app_metadata?.roles || []
    const loggedIn = roles.includes('admin') || roles.includes('member')
    return (
        <Link to={resolveLink(props.full_slug)} className="group">
            <FluidImage image={data.featured_image} className="group-hover:-translate-y-2 duration-300"/>
            <div className="mt-5 text-sm text-[#9C9C9C] tracking-tight flex flex-row justify-between items-center">
                {data.component === 'insight' ? 'Insights' : tags}&nbsp;&nbsp;鈥�&nbsp;&nbsp;{date}
                {(data.component === 'insight' && !loggedIn) && <PadlockIcon/>}
            </div>
            <h3 className="mt-1.5 text-xl font-light group-hover:text-orange duration-300 tracking-tight w-5/6">{props.name}</h3>
        </Link>
    )
}

export default ArchiveItem
